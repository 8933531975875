import { observer } from 'mobx-react-lite';
import { useCallback, type FunctionComponent } from 'react';

import { writePlacedOrderToSession } from '@/react/utils/checkout-utils/writePlacedOrderToSession';
import { withPreventUnload } from '@/react/utils/dom-utils';
import { getBrowserData } from '@/react/utils/getBrowserData';
import type CartVM from '@/react/view-models/CartVM';
import { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { PaymentInterruptedError } from '@/services/isomorphic/PaymentRequestService';
import type { IGetExpressCheckoutDetailsResponse } from '@/services/serverless/integrations/ServerPayPalNVPService';

import { useRevalidateCartWithPush } from '@/react/hooks/useRevalidateCartWithPush';
import NotificationType from '../../utility/notifications/NotificationType';
import type { IBasicNotification } from '../../utility/notifications/types';
import useNotification from '../../utility/notifications/useNotification';
import { PayPalButton } from '../PayPalButton';
import { general_errors_headlineMessage } from "@/lang/__generated__/ahnu/general_errors_headlineMessage";
import { general_errors_unexpectedError } from "@/lang/__generated__/ahnu/general_errors_unexpectedError";

export interface IExpressCheckoutWithPayPalButtonProps {
  /** The CartVM representing the current cart.   */
  cart: CartVM;
}

/**
 * Renders a {@link PayPalButton} preconfigured for Express Checkout.
 *
 * When clicked, it will attempt to create an order from provided cart and place
 * it when the user approves the transaction.
 */
export const ExpressCheckoutWithPayPalButton: FunctionComponent<IExpressCheckoutWithPayPalButtonProps> =
  observer(({ cart }) => {
    const queueNotification = useNotification();

    const [revalidateCartAndPush] = useRevalidateCartWithPush();

    // On approval, PayPal buttons call their `onApprove` handler with the result
    // of a `GetExpressCheckoutDetails` call for the order being approved.
    const onApprove = useCallback(
      async (details: IGetExpressCheckoutDetailsResponse) => {
        try {
          await withPreventUnload(async () => {
            const browserData = getBrowserData();
            const placedOrder = await cart.placePayPalOrder(
              details,
              browserData
            );

            writePlacedOrderToSession(placedOrder);

            // revalidate the cart since it was emptied by the server after order placement
            revalidateCartAndPush(
              `/checkout/confirmation?token=${placedOrder.orderToken}`
            );
          });
        } catch (error) {
          if (error instanceof PaymentInterruptedError) {
            // Log the error and return without displaying errors.
            LoggerService.error(error);
            return;
          }

          // For unknown errors, log them and show the user a notification.
          LoggerService.error(
            new Error(
              'An unexpected error occurred when doing PayPal Express Checkout.',
              { cause: error }
            )
          );

          queueNotification({
            type: NotificationType.Basic,
            content: {
              title: msg(general_errors_headlineMessage),
              body: msg(general_errors_unexpectedError)
            },
            options: {
              isError: true
            }
          } as IBasicNotification);
        }
      },
      [cart, queueNotification, revalidateCartAndPush]
    );

    return <PayPalButton mode="express" cart={cart} onApprove={onApprove} />;
  });
